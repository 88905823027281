import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { getUserCurrency } from "../../../services/globalHelpers"

const PanelToggle = ({ states, setStates, docType }) => {
  const [currencySymbol, setCurrencySymbol] = useState("$")
  const activatePanel = (e, type) => {
    e.preventDefault()
    setStates({ ...states, [`show${type}Panel`]: !states[`show${type}Panel`] })
  }

  useEffect(() => {
    const currencyData = getUserCurrency()
    if (currencyData) {
      setCurrencySymbol(currencyData.symbol)
    }
  }, [])

  return (
    <div className="col-lg-12 footer-action mb-3 text-center">
      <div className="footer-action-btns">
        {(states.driveType !== "folder" && ![
          "dashboardFullscreen",
          "faq",
          "review",
          "mailMergeDoc",
          "CVResume",
          "regularDoc",
          "press",
          "blog",
          "emailNewsletter",
        ].includes(docType)) && (
            <>
              <button
                className={`btnTogglePanel ${states.showForThemPanel ? "active" : ""}`}
                onClick={e => activatePanel(e, "ForThem")}
                data-panel="for_them_panel"
                data-bs-toggle="tooltip"
                title="FOR THEM: User Experience Flow"
              >
                <i className="bi bi-box-arrow-right"></i>
              </button>{" "}
              <button
                className={`btnTogglePanel ${states.showDhaPanel ? "active" : ""}`}
                onClick={e => activatePanel(e, "Dha")}
                data-panel="for_you_panel"
                data-bs-toggle="tooltip"
                title="FOR YOU: Data Handling & Automation"
              >
                <i className="bi bi-gear-fill"></i>
              </button>{" "}
            </>
          )}
        {states.driveType !== "invoice" && states.driveType !== "folder" && (
          <>
            <button
              className={`btnTogglePanel ${states.showInstallationPanel ? "active" : ""}`}
              onClick={e => activatePanel(e, "Installation")}
              data-panel="installation_panel"
              data-bs-toggle="tooltip"
              title="Installation Options"
            >
              <i className="bi bi-code-slash"></i>
            </button>{" "}
          </>
        )}
        {states.driveType === "invoice" && (
          <>
            <button
              className={`btnTogglePanel ${states.showInstallationPanel ? "active" : ""}`}
              onClick={e => activatePanel(e, "Installation")}
              data-panel="installation_panel"
              data-bs-toggle="tooltip"
              title="Installation Options"
            >
              <i className="bi bi-megaphone-fill"></i>
            </button>{" "}
            <button
              className={`btnTogglePanel ${states.showAccountingPanel ? "active" : ""}`}
              onClick={e => activatePanel(e, "Accounting")}
              data-panel="accounting_panel"
              data-bs-toggle="tooltip"
              title="Accounting"
            >
              {/* <i className="bi bi-currency-rupee"></i> */}
              &thinsp;{currencySymbol}&thinsp;
            </button>{" "}
          </>
        )}
        {states.driveType === "folder" && (
          <>
            <button
              className={`btnTogglePanel ${states.showRolesPanel ? "active" : ""}`}
              onClick={e => activatePanel(e, "Roles")}
              data-panel="people_and_roles_panel"
              data-bs-toggle="tooltip"
              title="People & Roles"
            >
              <i className="bi bi-person-fill"></i>
            </button>{" "}
            <button
              className={`btnTogglePanel ${states.showFilesPanel ? "active" : ""}`}
              onClick={e => activatePanel(e, "Files")}
              data-panel="files_panel"
              data-bs-toggle="tooltip"
              title="Files"
            >
              <i className="bi bi-paperclip"></i>
            </button>{" "}
          </>
        )}
        <button
          className={`btnTogglePanel ${states.showMoreDetailsPanel ? "active" : ""}`}
          onClick={e => activatePanel(e, "MoreDetails")}
          data-panel="more_panel"
          data-bs-toggle="tooltip"
          title="More Options"
        >
          <i className="bi bi-three-dots"></i>
        </button>
      </div>
    </div>
  )
}

export default PanelToggle

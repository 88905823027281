import React from "react"
import { Accordion } from "react-bootstrap"
import Comments from "../Doc/MoreOptions/comments"
import Privacy from "../Doc/MoreOptions/privacy"
import Tags from "../Doc/MoreOptions/tags"

const MoreOptionsPanel = ({
  tags,
  setTags,
  tempTags,
  setTempTags,
  docPrivacy,
  setDocPrivacy,
  whoCanModify,
  setWhoCanModify,
  privacyPassword,
  whoCanCopy,
  setWhoCanCopy,
  docComments,
  setDocComments,
  commentVisibility,
  setCommentVisibility,
  showMoreDetailsPanel,
}) => {
  return (
    <div className={`col-lg-12 mb-3${!showMoreDetailsPanel ? " d-none" : ""}`} id="more_panel">
      <Accordion
        defaultActiveKey="0"
        className="accordion-item panel panel-grey"
        alwaysOpen
        id="more_container"
      >
        <Accordion.Header>
          <span className="panel-heading-icon">
            <i className="bi bi-three-dots" />
          </span>
          <span className="panel-heading-title">More Options</span>
        </Accordion.Header>
        <Accordion.Body>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 mb-3 assetPrivacyContainer">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-lock-fill" /> Privacy
                    </strong>
                  </div>
                  <Privacy
                    docPrivacy={docPrivacy}
                    setDocPrivacy={setDocPrivacy}
                    whoCanModify={whoCanModify}
                    setWhoCanModify={setWhoCanModify}
                    privacyPassword={privacyPassword}
                    whoCanCopy={whoCanCopy}
                    setWhoCanCopy={setWhoCanCopy}
                  />
                </div>
                <div className="col-lg-12 mb-3 assetPrivacyContainer">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-person-circle" /> This data is..
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    "Cold" contacts. I cannot prove Opt-in.{" "}
                    <a href="http://swiftcrm.com/support/email" target="_blank">
                      <i className="bi bi-question-circle-fill" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-tags-fill" /> Tags
                    </strong>
                  </div>
                  <Tags tags={tags} setTags={setTags} tempTags={tempTags} setTempTags={setTempTags} />
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="colHeading">
                    <strong>
                      <i className="bi bi-file-image" /> Featured Image
                    </strong>
                  </div>
                  <div className="dh-block-body">
                    <div className="folderIcon">
                      <img src={`${process.env.GATSBY_WRANGLER_URL}/StaticImages/no-image.jpg`} />
                    </div>
                    <button type="button" className="btn btn-default btnDocFormIconEdit">
                      <span data-bs-toggle="tooltip" data-placement="bottom" title="Edit folder image">
                        <i className="bi bi-pencil-fill" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion>
    </div>
  )
}

export default MoreOptionsPanel
